<template>
  <process-table />
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";

import ProcessTable from "../../components/process/ProcessTable";
import { FETCHING_PROCESSES } from "../../store/modules/process/process.actions.type";
import { GET_PROCESSES } from "../../store/modules/process/process.getters.type";

export default {
  name: "Videos",
  components: {
    ProcessTable,
  },
  setup() {
    const store = useStore();

    onMounted(async () => {
      if (store.getters[GET_PROCESSES].length === 0) {
        await store.dispatch(FETCHING_PROCESSES);
      }
    });
  },
};
</script>
