<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <Toolbar class="p-mb-4">
        <template #start>
          <h5 class="p-m-0">
            {{ $t("process.table_title") }}
          </h5>
        </template>

        <template #end>
          <Button
            label=""
            icon="pi pi-refresh"
            class="p-button-secondary p-mr-2 p-mb*2"
            data-testid="table-refresh"
            @click="refreshTable"
          />
        </template>
      </Toolbar>
      <DataTable
        :value="processes"
        :paginator="true"
        :rows="10"
        :rows-per-page-options="[5, 10, 25]"
      >
        <Column :header="translation.image">
          <template #body="slotProps">
            <span class="p-column-title">Image</span>
            <img
              :src="slotProps.data?.video?.getFrame()"
              :alt="slotProps.data.name"
              class="video-frame"
            />
          </template>
        </Column>
        <Column :header="translation.date" :sortable="true">
          <template #body="slotProps">
            {{ dateToString(slotProps.data.createdAt) }}
          </template>
        </Column>
        <Column field="name" :sortable="true" :header="translation.name" />
        <Column field="keywords" :header="translation.tags">
          <template #body="slotProps">
            <span class="p-column-title">Tags</span>
            <Tag
              v-for="value in slotProps.data.keywords"
              :key="value"
              class="p-mr-2"
              rounded
            >
              {{ value }}
            </Tag>
          </template>
        </Column>
        <Column field="status" :header="translation.status" :sortable="true">
          <template #body="slotProps">
            <span
              :class="
                'video-status status-' + slotProps.data.status?.toLowerCase()
              "
              >{{ slotProps.data.status }}</span
            >
          </template>
        </Column>
        <Column style="text-align: right">
          <template #body="slotProps">
            <Button
              icon="pi pi-search"
              class="p-button-rounded p-button-info p-mr-2"
              @click="onDetails(slotProps.data)"
            />
            <Button
              icon="pi pi-times"
              class="p-button-rounded p-button-danger"
              :disabled="!slotProps.data?.canBeDeleted()"
              :data-testid="slotProps.data.id"
              @click="onOpenDeleteModal(slotProps.data)"
            />
          </template>
        </Column>
        <template #empty>{{ $t("process.table_empty") }} </template>
      </DataTable>
    </div>
    <Dialog
      v-model:visible="showModal"
      :style="{ width: '450px' }"
      :header="translation.dialogHeader"
      :modal="true"
      class="p-fluid p-pb-0"
    >
      <div class="confirmation-content">
        <p>{{ $t("process.modal_delete.message") }}</p>
        <p>
          <i>{{ $t("process.modal_delete.message_warning") }}</i>
        </p>
      </div>
      <template #footer>
        <Button
          :label="translation.dialogCancel"
          icon="pi pi-times"
          class="p-button-text"
          data-testid="buttonCancel"
          @click="showModal = false"
        />
        <Button
          :label="translation.dialogDelete"
          icon="pi pi-check"
          class="p-button-danger"
          autofocus
          data-testid="buttonDelete"
          @click="onSubmitDelete"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Tag from "primevue/tag";
import Toolbar from "primevue/toolbar";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { TOAST_LIFE } from "../../config";
import { dateTransformer } from "../../service/utils";
import {
  DELETING_PROCESS,
  FETCHING_PROCESSES,
} from "../../store/modules/process/process.actions.type";
import { GET_PROCESSES } from "../../store/modules/process/process.getters.type";

export default {
  name: "ProcessTable",
  components: {
    Button,
    Column,
    DataTable,
    Dialog,
    Tag,
    Toolbar,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();
    const { t } = useI18n();
    const processes = computed(() => store.getters[GET_PROCESSES]);
    const showModal = ref(false);
    let processToDelete;
    const translation = {
      image: t("process.table_column_image"),
      date: t("process.table_column_date"),
      name: t("process.table_column_name"),
      tags: t("process.table_column_tags"),
      status: t("process.table_column_status"),
      dialogHeader: t("process.modal_delete.header"),
      dialogDelete: t("common.delete"),
      dialogCancel: t("common.cancel"),
    };

    const refreshTable = async () => await store.dispatch(FETCHING_PROCESSES);

    const onDetails = async (data) => {
      const name = data.isFinished() ? "finished-video" : "verify-video";
      await router.push({ name: name, params: { processId: data.id } });
    };

    async function onOpenDeleteModal(data) {
      await store.dispatch(FETCHING_PROCESSES);
      showModal.value = true;
      processToDelete = { ...data };
    }

    const onSubmitDelete = async () => {
      await store.dispatch(DELETING_PROCESS, processToDelete.id);
      showModal.value = false;
      toast.add({
        severity: "success",
        summary: t("common.success"),
        detail: t("process.modal_delete.toast_success"),
        life: TOAST_LIFE,
      });
    };

    const dateToString = (date) => dateTransformer.toString(date);

    return {
      processes,
      dateToString,
      onDetails,
      onOpenDeleteModal,
      onSubmitDelete,
      refreshTable,
      showModal,
      translation,
    };
  },
};
</script>

<style scoped>
.video-frame {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.video-status {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.status-pending {
  background: #18b5eb;
  color: #003561;
}

.status-validated {
  background: #18b5eb;
  color: #003561;
}

.status-finished {
  background: #c8e6c9;
  color: #256029;
}

.status-rejected {
  background: #ffcdd2;
  color: #c63737;
}

.status-paid {
  background: #0678dd;
  color: #ffffff;
}

.status-processing {
  background: #0678dd;
  color: #ffffff;
}

.status-failed {
  background: tomato;
  color: white;
}
</style>
